<template>
  <div class="evaluate__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      岗位评价管理
    </div>
    <div class="content">
      <a-row>
        <a-col :span="24">
          <div class="recruit_tab" style="margin-bottom: 24px">
            <a-row>
              <a-col :span="5" v-for="(item, index) in tabs" :key="item.perms" v-perms="item.perms">
                <div
                  class="recruit_tabItem"
                  :class="[val == item.status ? 'active_recruit_tabItem' : '']"
                  @click="getTab(item.status)"
                >
                  {{ item.value }}
                </div>
              </a-col>
            </a-row>
          </div>
          <a-row type="flex">
            <a-col :span="24">
              <a-row v-perms="'search'">
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">项目部:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="projectDepartmentId"
                          :options="Departmentlist"
                          placeholder="请选择项目部"
                          @change="changeDepartment"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">项目名称:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="projectId"
                          :options="Projectlist"
                          placeholder="请选择项目名称"
                          @change="changeProjectlist"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="recruitUse_text">
                    <a-row>
                      <a-col :span="6">工种/专业:</a-col>
                      <a-col :span="12">
                        <a-select
                          ref="select"
                          v-model="positionId"
                          :options="Positionlist"
                          placeholder="请选择工种/专业"
                          @change="changePosition"
                          style="width: 200px"
                          allowClear
                        ></a-select>
                      </a-col>
                    </a-row>
                  </div>
                </a-col>
              </a-row>
              <a-spin :spinning="spinning">
                <div class style="margin-top: 24px" v-if="val == 1">
                  <a-table
                    class="table--layout"
                    @change="handleTableChange"
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="pagination"
                    :scroll="{ y: 480 }"
                  >
                    <template slot="score" slot-scope="record">
                      <a-rate :default-value="Number(record)" slot="score" disabled />
                    </template>
                    <template slot="operation" slot-scope="text, record">
                      <div class="operation_btn" @click="detail(record)" v-perms:ypj="'details'">详情</div>
                    </template>
                  </a-table>
                </div>
                <div class style="margin-top: 24px" v-if="val == 0">
                  <a-table
                    class="table--layout"
                    :scroll="{ y: 480 }"
                    @change="handleTableChange"
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="pagination"
                  >
                    <template slot="operation" slot-scope="text, record">
                      <a-button type="link" @click="Evaluate(record)" v-perms:dpj="'pj'">评价</a-button>
                    </template>
                  </a-table>
                </div>
              </a-spin>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <evaluate ref="evaluate" />
  </div>
</template>

<script>
import {
  employmentEmployDepartment,
  enterpriseEmploymentManagement,
  enterpriseEmploymentPosition,
  employmentEvaluatelist
} from '@/api/recruitUse'
import { tableMixins } from '@/mixins/table-height'
import moment from 'moment'
import evaluate from './evaluate.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '姓名',
    dataIndex: 'realName'
  },
  {
    title: '性别',
    dataIndex: 'sex',
    customRender: (text) => (text === 0 ? '男' : text === 1 ? '女' : '其他')
  },
  {
    title: '年龄',
    dataIndex: 'age'
  },
  {
    title: '联系电话',
    dataIndex: 'phone'
  },
  {
    title: '评分结果',
    dataIndex: 'score',
    scopedSlots: { customRender: 'score' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  name: 'Index',
  components: {
    evaluate
  },
  mixins: [tableMixins(425, 'table--layout')],
  data () {
    return {
      autoExpandParent: true,
      projectDepartmentId: undefined,
      positionId: undefined,
      projectId: undefined,
      visible: false,
      val: 1,
      isEvaluate: true,
      Departmentlist: [],
      Projectlist: [],
      Positionlist: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0
      },
      content: '',
      evaluationDetail: {},
      typeList: [],
      typeSelect: [],
      rate: 0,
      evaluationRow: '',
      spinning: false,
      confirmLoading: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    columns () {
      return columns.filter((item) => this.val === 1 || item.dataIndex !== 'score')
    },
    tabs () {
      const arr = [
        {
          value: '已评价',
          perms: 'ypj',
          status: 1
        },
        {
          value: '待评价',
          perms: 'dpj',
          status: 0
        }
      ]
      return arr
    }
  },
  methods: {
    init () {
      // this.handleGetTypeList()
      employmentEmployDepartment()
        .then((res) => {
          res.data.forEach((v) => {
            v.label = v.projectDepartment
            v.value = v.projectDepartmentId
          })
          this.Departmentlist = res.data
        })
        .catch((err) => {
          console.log(err)
        })
      this.handleGetData()
    },
    // handleGetTypeList () {
    //   getTypeDetail().then((res) => {
    //     this.typeList = res.data
    //   })
    // },
    changePosition (val) {
      this.handleGetData()
    },
    handleGetData () {
      this.spinning = true
      const params = {
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        positionId: this.positionId,
        projectDepartmentId: this.projectDepartmentId,
        projectId: this.projectId,
        status: this.val
      }
      if (params) {
        employmentEvaluatelist(params).then((res) => {
          this.tableData = res.data
          this.spinning = false
          this.pagination.total = res.totalCount
        })
      } else {
        this.tableData = []
        this.spinning = false
        this.pagination.current = 1
        this.pagination.pageSize = 10
        this.pagination.total = 0
      }
    },
    changeProjectlist (val) {
      this.positionId = undefined
      this.Positionlist = []
      if (val) {
        const params = {
          projectId: val
        }
        enterpriseEmploymentPosition(params)
          .then((res) => {
            console.log(res)
            res.data.forEach((v) => {
              v.label = v.position
              v.value = v.demandId
            })
            this.Positionlist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
      this.handleGetData()
    },
    // 获取项目名称
    changeDepartment (val) {
      this.projectId = undefined
      this.positionId = undefined
      this.Projectlist = []
      this.Positionlist = []
      if (val) {
        const params = {
          projectDepartmentId: val
        }
        enterpriseEmploymentManagement(params)
          .then((res) => {
            res.data.forEach((v) => {
              v.label = v.projectName
              v.value = v.projectId
            })
            this.Projectlist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
      this.handleGetData()
    },
    Evaluate (row) {
      this.$refs.evaluate.init({
        signId: row.id,
        author: row.enterpriseId,
        authorName: row.enterpriseName,
        clientId: 'enterprise',
        userId: row.userId,
        disabled: true
      })
    },
    getTab (val) {
      this.val = val
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.tableData = []
      this.handleGetData()
    },
    detail (row) {
      this.$refs.evaluate.lookInit({
        row: row,
        disabled: false
      })
      // this.evaluationDetail.typeList = row.tab ? row.tab.split(',') : []
    },
    handleTableChange (pagination) {
      this.pagination = pagination
      this.handleGetData()
    },
    handleTypeClick (key) {
      if (this.typeSelect.includes(key)) {
        this.typeSelect = this.typeSelect.filter((item) => item !== key)
      } else {
        this.typeSelect.push(key)
      }
    },
    formatTime (time) {
      return time ? moment(time).format('YYYY-MM-DD') : null
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../recruit/index');
@import url('./index');
.evaluate__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
}
</style>
